@import '../../theme/colors';
@import '../../theme/spacing';
@import '../../theme/opacities';

.wrapper {
  display: flex;
  flex-direction: row;
}

.checkbox {
  margin-top: 0.35rem;
}

.label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: $spacing-xxs;
  color: $color-black;
  cursor: pointer;

  &-disabled {
    color: rgba($color-black, $opacity-disabled);
  }
}
