@import '../../theme/colors';
@import '../../theme/spacing';
@import '../../theme/opacities';
@import '../../theme/state-colors';
@import '../../theme/border';
@import '../../theme/animations';

.checkbox {
  display: block;
  display: flex;
  position: relative;
  z-index: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: $spacing-xs;
  height: $spacing-xs;
  border: $border-width-input solid;
  border-radius: $border-radius-xxs;
  outline: none;
  cursor: pointer;
  appearance: none;

  /* Focus indicator */
  &::before {
    content: ' ';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    transition: opacity $small-intro;
    border: 0.375rem solid rgba($color-orange, $opacity-light);
    border-radius: $border-radius-xs;
    opacity: 0;
  }

  /* Checkmark */
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    transition: opacity $small-intro;
    opacity: 0;
    background-image: url("data:image/svg+xml;utf8,<svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.47339 4.85233L4.09485 6.86554L8.57571 1.13208' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    background-repeat: no-repeat;
    background-position: center;
  }

  &:focus-visible::before {
    opacity: 1;
  }

  &:checked {
    background: $color-orange;

    &::after {
      opacity: 1;
    }
  }
}

.default {
  border-color: $color-black;
}

.error {
  border-color: $state-color-error;
}

.disabled {
  border-color: rgba($color-black, $opacity-disabled);

  &:checked {
    background: transparent;

    &::after {
      opacity: $opacity-disabled;
    }
  }
}
