@import '../../../photon/theme/global';

.form {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  padding-bottom: $spacing-m;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    padding-right: max($spacing-xs, calc((100% - #{$breakpoint-small}px) / 2));
    padding-bottom: $spacing-l;
    padding-left: max($spacing-xs, calc((100% - #{$breakpoint-small}px) / 2));
  }
}

.ul,
.li {
  padding-bottom: 0;
}
